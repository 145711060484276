export const BlockMatrix = {
    I: [
        [0, 0, 0, 0],
        [1, 1, 1, 1],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
      ],
    J: [
        [1, 0, 0],
        [1, 1, 1],
        [0, 0, 0],
    ],
    L: [
        [0, 0, 1],
        [1, 1, 1],
        [0, 0, 0],
    ],
    O: [
        [1, 1], 
        [1, 1]
    ],
    S: [
        [0, 1, 1], 
        [1, 1, 0],
        [0, 0, 0],
    ],
    T: [
        [0, 1, 0], 
        [1, 1, 1],
        [0, 0, 0]
    ],
    Z: [
        [1, 1, 0], 
        [0, 1, 1],
        [0, 0, 0]
    ],
}

export const BlockRotationMatrix = {
    I: [
        [
            [2, 2, 1, 0],
            [0, 0, 1, 0],
            [0, 0, 1, 2],
            [0, 0, 1, 2],
          ],
          [
            [0, 0, 0, 2],
            [0, 0, 0, 2],
            [1, 1, 1, 1],
            [2, 2, 0, 0],
          ],
          [
            [2, 1, 0, 0],
            [2, 1, 0, 0],
            [0, 1, 0, 0],
            [0, 1, 2, 2],
          ],
          [
            [0, 0, 2, 2],
            [1, 1, 1, 1],
            [2, 0, 0, 0],
            [2, 0, 0, 0],
          ]
      ],
    J: [
        [
            [0, 1, 1],
            [0, 1, 0],
            [0, 1, 2],
          ],
          [
            [0, 0, 0],
            [1, 1, 1],
            [2, 0, 1],
          ],
          [
            [2, 1, 0],
            [0, 1, 0],
            [1, 1, 0],
          ],
          [
            [1, 0, 2],
            [1, 1, 1],
            [0, 0, 0],
          ]
    ],
    L: [
        [
            [2, 1, 0],
            [0, 1, 0],
            [0, 1, 1],
          ],
          [
            [0, 0, 2],
            [1, 1, 1],
            [1, 0, 0],
          ],
          [
            [1, 1, 0],
            [0, 1, 0],
            [0, 1, 2],
          ],
          [
            [0, 0, 1],
            [1, 1, 1],
            [2, 0, 0],
          ]
    ],
    O: [
        [
            [1, 1], 
            [1, 1]
          ],
          [
            [1, 1], 
            [1, 1]
          ],
          [
            [1, 1], 
            [1, 1]
          ],
          [
            [1, 1], 
            [1, 1]
          ]
    ],
    S: [
        [
            [2, 1, 2], 
            [0, 1, 1],
            [0, 0, 1],
          ],
          [
            [0, 0, 2], 
            [0, 1, 1],
            [1, 1, 2],
          ],
          [
            [1, 0, 0], 
            [1, 1, 0],
            [2, 1, 2],
          ],
          [
            [2, 1, 1], 
            [1, 1, 0],
            [2, 0, 0],
          ]
    ],
    T: [
        [
            [2, 1, 2], 
            [0, 1, 1],
            [0, 1, 2]
          ],
          [
            [0, 0, 2], 
            [1, 1, 1],
            [2, 1, 2]
          ],
          [
            [2, 1, 0], 
            [1, 1, 0],
            [2, 1, 2]
          ],
          [
            [2, 1, 2], 
            [1, 1, 1],
            [2, 0, 0]
          ]
    ],
    Z: [
        [
            [0, 2, 1], 
            [0, 1, 1],
            [0, 1, 2]
          ],
          [
            [0, 0, 0], 
            [1, 1, 2],
            [2, 1, 1]
          ],
          [
            [2, 1, 0], 
            [1, 1, 0],
            [1, 2, 0]
          ],
          [
            [1, 1, 2], 
            [2, 1, 1],
            [0, 0, 0]
          ]
    ],
}